import { OCPayment, OfferStatus, OutstandingLoan, PaymentFrequency } from '@mca/shared/domain';
import { dateAsYYYYMMDD } from '@mca/shared/util';
import { McaCommisionUser } from './commisionuser';
import { MCAProgramTypes, programOptions } from './mca-consts';
import { McaScheduleState } from './mca-schedule';
import { McaCommisionUserDisp, MCAPosition, MCARec } from './mcarec';

export const OfferStatusOrder: { [key in OfferStatus]: number } = {
  [OfferStatus.Expired]: 0,
  [OfferStatus['']]: 1,
  [OfferStatus.New]: 2,
  [OfferStatus['Sent to broker']]: 3,
  [OfferStatus['Contract requested']]: 4,
  [OfferStatus['Contract out']]: 5,
  [OfferStatus['Contract in']]: 6,
  [OfferStatus['Contract in Exclusive']]: 7,
  [OfferStatus['Ready for funding']]: 8,
  [OfferStatus.Funded]: 9,
};

export interface OfferCommission {
  userid: number;
  percent: number;
  base_type: number;
  paystrategy: number;
  venueid: number;
  contract_fee: number;
}

export class CommUserSet {
  setindex = 0;
  users: McaCommisionUserDisp[] = [];
  totalAmtCommisionUsers = 0;
  totalPctCommisionUsers = 0;
  cfAmount = 0;
  cfPct = 0;
  totalComm = 0;
  dealvenue = 1;
  draft: boolean;

  constructor(setindex: number, defaultVenue?: number, users: McaCommisionUserDisp[] = [], draft = false) {
    this.users = [...users].map(u => ({ ...u }));
    this.setindex = setindex;
    this.draft = draft;
    this.dealvenue = defaultVenue || 1;
  }
}

export interface OfferTransaction {
  amount: number;
  quantity: number;
  fee?: number;
}

export type DiffPropNames = 'balanceRemains' | 'payment' | 'consolidate';

export interface OfferOutstandings {
  difference: {
    [name: string]: {
      [prop in DiffPropNames]?: {
        deal: number;
        offer: number;
      };
    };
  };
  match: boolean;
  records: boolean;
}

export interface McaOfferData {
  amount: number;
  commissionset: number;
  funding_type: MCAProgramTypes;
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  withdrawal_payment: number;
  withdrawal_freq: PaymentFrequency;
  deposit_freq: PaymentFrequency;
  switch_to_daily_payments: boolean;
  commissions: OfferCommission[];
  deposit: OfferTransaction[];
  withdrawal: OfferTransaction[];
  rates: { expiry?: string; rate: number }[];
  advancesoffer: any[] | null;
  fixed_payment: boolean;
  ach_for_deposits: boolean;
  ach_for_payments: boolean;
  contract_fee_pct: number;
  fixed_cf: number;
  sent_at: string;
  outstandings: OfferOutstandings;
  status: OfferStatus;
  payms_number: number;
  template_id: number | null;
  admin_fee?: number;
  nsf_fee?: number;
}

export class McaOffer implements McaOfferData {
  amount = 0;
  commissionset = 0;
  funding_type = MCAProgramTypes.deal;
  id = 0;
  name = '';
  created_at = '';
  updated_at = '';
  withdrawal_payment = 0;
  withdrawal_freq = PaymentFrequency.daily;
  deposit_freq = PaymentFrequency.daily;
  switch_to_daily_payments = false;
  commissions: OfferCommission[] = [];
  deposit: OfferTransaction[] = [];
  withdrawal: OfferTransaction[] = [];
  rates: { expiry?: string; rate: number }[] = [];
  advancesoffer: any[] | null = null;
  fixed_payment = false;
  ach_for_deposits = false;
  ach_for_payments = false;
  contract_fee_pct = 0;
  fixed_cf = 0;
  sent_at = '';
  outstandings = {} as OfferOutstandings;
  status = OfferStatus.New;
  payms_number = 0;
  template_id: number | null = null;
  admin_fee?: number;
  nsf_fee?: number;
  is_private = false;

  static isStatusInContract(status: OfferStatus) {
    return [OfferStatus['Contract out'], OfferStatus['Contract in'], OfferStatus['Contract in Exclusive']].includes(status);
  }

  static offerToMcaData(offer: McaOffer, baseMca: MCARec) {
    const outstandingLoans = offer.advancesoffer
      ? offer.advancesoffer.map((offerLoan: any) => {
          const loan = new OutstandingLoan();
          const loanData = {
            name: offerLoan.name,
            fundingDate: new Date(offerLoan.funding_date),
            fundingAmmount: offerLoan.funding_amount,
            date: new Date(offerLoan.balance_asof),
            ignorefundingdate: offerLoan.ignore_funding_date,
            calcDate: new Date(offerLoan.calc_date),
            ammount: offerLoan.amount,
            factorRate: offerLoan.factor_rate,
            payment: offerLoan.payment,
            balanceRemains: offerLoan.balance,
            consolidate: offerLoan.consolidate,
            buyout: offerLoan.buyout,
            refmca: offerLoan.refmcaid,
            renewal: offerLoan.renewal,
            weekly_payment: offerLoan.weekly_payment,
          };
          Object.assign(loan, loanData);
          return loan;
        })
      : [...baseMca.outstandingLoans];

    const mcaData: Partial<MCARec> | { position: Partial<MCAPosition> } = {
      position: {
        payment_amount: offer.withdrawal_payment,
        funding_amount: offer.amount,
        program: +offer.funding_type,
        switch_to_daily_payments: offer.switch_to_daily_payments,
      },
      paybackAmount: offer.amount * offer.getRate(),
      num_payments: offer.withdrawal?.reduce((acc, w) => acc + w.quantity, 0),
      achWithdrawalAmt: offer.withdrawal_payment,
      withdFreq: offer.withdrawal_freq,
      depFreq: offer.deposit_freq,
      factorRate: offer.rates[0].rate,
      discountFactorRate: offer.rates[1]?.rate,
      useACHForDeposits: offer.ach_for_deposits,
      useACHForPayments: offer.ach_for_payments,
      outstandingLoans,
    };

    return mcaData;
  }

  static expandPayments(transactions: OfferTransaction[]) {
    const payments: OCPayment[] = [];
    transactions.forEach(t => {
      let quantity = t.quantity;
      while (quantity--) {
        payments.push({ ammount: t.amount, fee: t.fee, effectivedate: undefined, processed: undefined });
      }
    });
    return payments;
  }

  static isActive(offer: McaOfferData) {
    return offer.status !== OfferStatus.Expired;
  }

  static isEditable(offer: McaOfferData) {
    return McaOffer.isActive(offer) && offer.status !== OfferStatus.Funded;
  }

  setMcaData(mca: MCARec) {
    const advances = mca.outstandingLoans.map(loan => ({
      name: loan.name ?? '',
      funding_date: dateAsYYYYMMDD(loan.fundingDate),
      funding_amount: loan.fundingAmmount,
      balance_asof: dateAsYYYYMMDD(loan.date),
      ignore_funding_date: loan.ignorefundingdate,
      calc_date: dateAsYYYYMMDD(loan.calcDate),
      amount: loan.ammount,
      factor_rate: loan.factorRate,
      payment: loan.payment,
      balance: loan.balanceRemains,
      consolidate: loan.consolidate,
      buyout: loan.buyout,
      refmcaid: loan.refmca,
      renewal: loan.renewal,
      weekly_payment: loan.weekly_payment,
    }));

    Object.assign(this, {
      // keep time for sorting
      name: new Date().toUTCString(),
      funding_type: mca.position.program,
      amount: mca.position.funding_amount,
      withdrawal_payment: mca.position.payment_amount,
      deposit: [{ quantity: 1, amount: mca.position.funding_amount, fee: mca.position.fee_pct ?? 0 }],
      withdrawal: [{ quantity: mca.num_payments, amount: mca.position.payment_amount }],
      switch_to_daily_payments: mca.position.switch_to_daily_payments,
      advances,
      ach_for_deposits: mca.useACHForDeposits,
      ach_for_payments: mca.useACHForPayments,
    });
  }

  setScheduleData({ state, deposits, withdrawals }: { state: McaScheduleState; deposits: OCPayment[]; withdrawals: OCPayment[] }) {
    Object.assign(this, {
      funding_type: state.offerForm.program,
      amount: state.offerForm.fundedAmt,
      withdrawal_payment: state.offerForm.withdrAmt,
      fixed_payment: state.offerForm.fixPayment,
      contract_fee_pct: state.offerForm.fee,
      fixed_cf: state.offerForm.fixed_cf,
      rates: [{ rate: state.offerForm.rate }, { rate: state.offerForm.discountFactorRate }].filter(r => !!r.rate),
      withdrawal_freq: state.offerForm.selectedWithdFreq,
      deposit_freq: state.offerForm.selectedDepFreq,
      template_id: state.offerForm.templateId ?? null,
      switch_to_daily_payments: state.offerForm.switch_to_daily_payments,
    });
    this.deposit = this.paymentsToOfferTransactions(deposits);
    this.withdrawal = this.paymentsToOfferTransactions(withdrawals);
  }

  setCommSetData(commSet: { users: McaCommisionUser[] }) {
    this.commissions = commSet.users.map(u => ({
      userid: u.userid,
      percent: u.commisionpct ?? 0,
      base_type: u.baseindicator,
      paystrategy: u.paystrategy,
      venueid: u.dealvenue,
      contract_fee: u.contract_fee_allocation ?? 0,
    }));
  }

  getRate() {
    // discount rate preferred
    return +this.rates?.[1]?.rate || +this.rates?.[0]?.rate;
  }

  getWithdrawalFrequency() {
    switch (this.withdrawal_freq) {
      case 5:
        return 'weekly';
      case 1:
        return 'daily';
    }
    return '';
  }

  getVenue() {
    return this.commissions[0]?.venueid;
  }

  getFundingTypeLabel = () => programOptions.find(opt => opt.value === +this.funding_type)?.label;

  private paymentsToOfferTransactions(payments: OCPayment[]) {
    const transactions: OfferTransaction[] = [];
    // convert payment sequence to one transaction with quantity of payments
    for (let i = 0, j = 0; i < payments.length; i++) {
      const curr = payments[i];
      const prev = transactions[j];
      if (prev?.amount === curr.ammount && prev?.fee === curr.fee) {
        prev.quantity++;
        continue;
      }
      const index = i > 0 ? ++j : 0;
      const baseValue = curr.fee === undefined ? {} : { fee: curr.fee };
      transactions[index] = { ...baseValue, amount: curr.ammount, quantity: 1 };
    }
    return transactions;
  }
}

export class OfferTemplateCommissions {
  iso_commission_rtr_pct = 0; // ISO
  iso_commission_rtr_bonus_pct = 0;
  iso_contract_fee_pct = 0; // ISO extra
  iso_contract_fee_bonus_pct = 0;

  isorep_commission_pct = 0; // ISO Rep
  isorep_commission_bonus_pct = 0;
  isorep_contract_fee_pct = 0;
  isorep_contract_fee_bonus_pct = 0;

  ins_commission_rtr_pct = 0; // INS
  commission_comm_rtr_bonus_pct = 0;
  ins_commission_contract_fee_pct = 0;
  commission_contract_fee_bonus_pct = 0;

  commission_comm_rtr_pct = 0; // Commissions
  ins_commission_rtr_bonus_pct = 0;
  commission_contract_fee_pct = 0;
  ins_commission_contract_fee_bonus_pct = 0;

  cf_commission_rtr_pct = 0; // CF
  cf_commission_rtr_bonus_pct = 0;
  cf_contract_fee_pct = 0;
  cf_contract_fee_bonus_pct = 0;
}

export interface OfferTemplate extends OfferTemplateCommissions {
  contract_fee_pct: number;
  crate: number;
  crate_days: number;
  created_at: string;
  drate: number;
  drate_days: number;
  funding_type: number;
  id: number;
  name: string;
  total_commission_rtr_pct: number;
  withdrawal_freq: number;
  deposit_freq: number | null;
  max_funding_amount: number | null;
  max_exposure: number | null;
  increments: number[];
  number_of_increments: number | null;
  is_active: boolean;
  use_consolidation_increments: boolean;
  switch_to_daily_payments: boolean;
  fixed_cf: number;
  children: OfferTemplateRelation[];
  parents: OfferTemplateRelation[];
}

export interface OfferTemplateRelation {
  name?: string;
  template_id: number;
}

export class OfferCommissionImpl {
  static totalCommission({
    iso_commission_rtr_pct,
    isorep_commission_pct,
    ins_commission_rtr_pct,
    commission_comm_rtr_pct,
    cf_commission_rtr_pct,
  }: OfferTemplateCommissions) {
    return (
      +(iso_commission_rtr_pct ?? 0) +
      +(isorep_commission_pct ?? 0) +
      +(ins_commission_rtr_pct ?? 0) +
      +(commission_comm_rtr_pct ?? 0) +
      +(cf_commission_rtr_pct ?? 0)
    );
  }

  static totalFee({
    iso_contract_fee_pct,
    isorep_contract_fee_pct,
    ins_commission_contract_fee_pct,
    commission_contract_fee_pct,
    cf_contract_fee_pct,
  }: OfferTemplateCommissions) {
    return (
      +(iso_contract_fee_pct ?? 0) +
      +(isorep_contract_fee_pct ?? 0) +
      +(ins_commission_contract_fee_pct ?? 0) +
      +(commission_contract_fee_pct ?? 0) +
      +(cf_contract_fee_pct ?? 0)
    );
  }

  static templateToCommissions(commissions: OfferTemplateCommissions) {
    const commissionKeys = Object.keys(new OfferTemplateCommissions());
    return Object.fromEntries(
      Object.entries(commissions)
        .filter(([key]) => commissionKeys.includes(key))
        .map(([key, value]) => [key, value || 0]),
    ) as OfferTemplateCommissions;
  }
}

export const OFFER_STATUSES_TO_PRELOAD = [OfferStatus['Ready for funding'], OfferStatus['Contract in Exclusive'], OfferStatus.Funded];
