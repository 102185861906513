import { Injectable, inject } from '@angular/core';
import { AuthService, PermissionsService } from '@mca/auth/api';
import { ReferencesService } from '@mca/references/api';
import { getPersonName, UserLoginInfo, UserReferenceService, UserService } from '@mca/user/api';
import { RxState } from '@rx-angular/state';
import { SelectItem } from 'primeng/api';
import { map, take } from 'rxjs';
import { AssignmentTypes, programOptions } from '../entities/mca-consts';
import { enumToOptions } from '@mca/shared/util';
import { McaRateTypes } from '@mca/shared/domain';

export interface McaListState {
  statuses: SelectItem[];
  fundingTypes: SelectItem[];
  isoRelations: SelectItem[];
  isos: SelectItem[];
  rateTypes: SelectItem[];
  assigneeOptions: SelectItem[];
  roleOptions: SelectItem[];
  userOptions: SelectItem[];
}

@Injectable({
  providedIn: 'root',
})
export class McaListService extends RxState<McaListState> {
  private refService = inject(ReferencesService);
  private usersService = inject(UserReferenceService);
  private permissionsService = inject(PermissionsService);
  private authService = inject(AuthService);
  private userService = inject(UserService);

  currentUser?: UserLoginInfo | null;
  defaultUserItems = [
    { label: 'Any User', value: null },
    { label: 'Assigned to me', value: this.currentUser?.id ?? 0 },
  ];

  constructor() {
    super();
    this.set({
      statuses: [],
      fundingTypes: [{ label: 'All Funding Types', value: '' }, ...programOptions],
      rateTypes: [{ value: 0, label: 'Any' }, ...enumToOptions(McaRateTypes)],
      assigneeOptions: [{ label: 'Any Type', value: 0 }, ...enumToOptions(AssignmentTypes)],
      roleOptions: [],
      userOptions: [],
      isoRelations: [],
      isos: [],
    });

    this.loadData();
  }

  getStatusLabel(status: number) {
    return this.refService.getStatusLabel(status);
  }

  private loadData() {
    this.currentUser = this.authService.currentUser();
    this.loadStatuses();
    this.loadRoles();
    this.loadUsers();
  }

  private loadStatuses() {
    this.connect(
      'statuses',
      this.refService
        .getStatuses()
        .pipe(map(statuses => [{ label: 'Any Status', value: '' }, ...(statuses.map(s => ({ label: s.status, value: s.id })) ?? [])])),
    );
  }

  private loadUsers() {
    if (!this.permissionsService.hasPermission('mca_filters_iso')) {
      this.set({
        isoRelations: [],
        isos: [],
      });
      return;
    }
    this.connect(
      this.usersService.users$.pipe(
        take(1),
        map((users: UserLoginInfo[]) => {
          const usersWithRoles = users.filter(u => u.roles);
          const userToOption = (u: any) => ({ label: getPersonName(u), value: u.id });
          const isoRelationOptions = usersWithRoles.filter(u => this.permissionsService.hasRole('role_iso_relations', u)).map(userToOption);
          const isoOptions = usersWithRoles.filter(u => this.permissionsService.isIso(u)).map(userToOption);
          return {
            isoRelations: [{ label: 'Any', value: 0 }, ...isoRelationOptions],
            isos: [{ label: 'Any', value: 0 }, ...isoOptions],
          };
        }),
      ),
    );
    this.connect(
      this.userService.getCache().pipe(
        map(users => ({
          userOptions: [...this.defaultUserItems, ...users.map(user => ({ value: user.id, label: user.name }))],
        })),
      ),
    );
  }

  private loadRoles() {
    this.connect(
      this.usersService.roles$.pipe(
        take(1),
        map(roles => ({
          roleOptions: [
            { label: 'Assigned to my role', value: this.currentUser?.roles ?? 0 },
            ...roles.map(role => ({
              label: role.name,
              value: role.id,
            })),
          ],
        })),
      ),
    );
  }

  filterUsers(roles: number[] | null | undefined) {
    this.connect(
      this.userService.getCache().pipe(
        map(users => {
          if (!roles?.length) {
            return {
              userOptions: [...this.defaultUserItems, ...users.map(user => ({ value: user.id, label: user.name }))],
            };
          } else {
            return {
              userOptions: [
                ...this.defaultUserItems,
                ...users
                  .filter(user => user.roles?.some(roleId => roles.includes(roleId)))
                  .map(user => ({ value: user.id, label: user.name })),
              ],
            };
          }
        }),
      ),
    );
  }
}
