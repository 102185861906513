import { Injectable, inject } from '@angular/core';
import { ApiService } from '@mca/shared/util';

import {
  httpMcaApproveContractFunding,
  httpMcaApproveContractReadyFF,
  httpMcaApproveContractRequest,
  httpMcaContractToFunding,
  httpMcaContractToReadyFF,
  httpMcaDeclineContractFunding,
  httpMcaFundingContracts,
  httpMcaGenerateAppendixA,
  httpMcaGenerateCOJ,
  httpMcaOffers,
  httpMcaOfferSend,
  httpMcaReadyFFContracts,
  httpMcaContractRequested,
  httpMcaRequestedContracts,
  httpMcaSendContract,
  httpMcaContractSigned,
  httpMcaContractSignedExclusive,
  httpMcaOfferExpire,
  httpMcaOfferTemplate,
  httpMcaOfferTemplateId,
  httpMcaGenerateExhibitA,
  httpISOActiveOffers,
  httpIsoOfferVerify,
  httpIsoOfferRequest,
  httpMcaOfferCalcIncrementalTemplateFa,
  httpMcaGenerateConsolidationGrid,
  httpMcaOfferBatchExpire,
  httpMcaOfferCopy,
} from './mca-http-points';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContractEvent } from '@mca/shared/domain';
import { McaOfferData, OfferTemplate, OfferOutstandings } from '../entities/offer';

@Injectable({
  providedIn: 'root',
})
export class OfferService {
  private apiService = inject(ApiService);

  getOffer(mcaid: number, offerid: number) {
    return this.apiService.get(httpMcaOffers(mcaid, offerid));
  }

  getOffers(mcaid: number): Observable<McaOfferData[]> {
    return this.apiService.get(httpMcaOffers(mcaid)).pipe(map(response => response.mcaoffers));
  }

  createOffer(mcaid: number, offer: McaOfferData) {
    return this.apiService.post(httpMcaOffers(mcaid), offer);
  }

  updateOffer(mcaid: number, offer: McaOfferData) {
    return this.apiService.put<{
      offerid: number;
    }>(httpMcaOffers(mcaid, offer.id), offer);
  }

  /**
   * force = false : send only if offer outstandings match the deal, otherwise return diff
   * force = true  : force send with offer outstandings
   */
  sendOffer(mcaid: number, offerIds: number[], force = false) {
    return this.apiService.post<{ sent: boolean; outstandings: OfferOutstandings }>(httpMcaOfferSend(mcaid), {
      offerid_list: offerIds,
      force,
    });
  }

  copyOffers(mcaid: number, offerIds: number[], userSets: number[]) {
    return this.apiService.post(httpMcaOfferCopy(mcaid), { offerid_list: offerIds, iso_set_idxs: userSets });
  }

  expireOffer(mcaid: number, offerid: number) {
    return this.apiService.post(httpMcaOfferExpire(mcaid, offerid));
  }

  expireOffers(mcaid: number, offerids: number[]) {
    return this.apiService.post(httpMcaOfferBatchExpire(mcaid), { offerids });
  }

  contractRequested(mcaid: number, offerid: number) {
    return this.apiService.post(httpMcaContractRequested(mcaid, offerid));
  }

  contractSigned(mcaid: number, offerid: number) {
    return this.apiService.post(httpMcaContractSigned(mcaid, offerid));
  }

  contractSignedExclusive(mcaid: number, offerid: number) {
    return this.apiService.post(httpMcaContractSignedExclusive(mcaid, offerid));
  }

  sendContract(mcaid: number, offerid: number) {
    return this.apiService.post(httpMcaSendContract(mcaid, offerid));
  }

  getRequestedContracts(): Observable<ContractEvent[]> {
    return this.apiService.get(httpMcaRequestedContracts());
  }

  approveContract(
    mcaid: number,
    offerid: number,
    data: {
      parent_id: number;
      note: string;
    },
  ) {
    return this.apiService.post(httpMcaApproveContractRequest(mcaid, offerid), data);
  }

  getReadyFFContracts(): Observable<ContractEvent[]> {
    return this.apiService.get(httpMcaReadyFFContracts());
  }

  setContractToReadyFF(mcaid: number, offerid_list: number[], note: string) {
    return this.apiService.post(httpMcaContractToReadyFF(mcaid), { offerid_list, note });
  }

  approveContractReadyFF(
    mcaid: number,
    offerid: number,
    data: {
      parent_id: number;
      note: string;
    },
  ) {
    return this.apiService.post(httpMcaApproveContractReadyFF(mcaid, offerid), data);
  }

  getFundingContracts(): Observable<ContractEvent[]> {
    return this.apiService.get(httpMcaFundingContracts());
  }

  setContractFunging(mcaid: number, offerid_list: number[], note: string) {
    return this.apiService.post(httpMcaContractToFunding(mcaid), { offerid_list, note });
  }

  approveContractFunding(
    mcaid: number,
    offerid: number,
    data: {
      parent_id: number;
      note: string;
    },
  ) {
    return this.apiService.post(httpMcaApproveContractFunding(mcaid, offerid), data);
  }

  declineContractFunding(
    mcaid: number,
    offerid: number,
    data: {
      parent_id: number;
      note: string;
    },
  ) {
    return this.apiService.post(httpMcaDeclineContractFunding(mcaid, offerid), data);
  }

  generateCOJ(mcaid: number, offerid: number) {
    return this.apiService.post(httpMcaGenerateCOJ(mcaid, offerid));
  }

  generateAppA(mcaid: number, offerid: number) {
    return this.apiService.post(httpMcaGenerateAppendixA(mcaid, offerid));
  }

  generateExhibitA(mcaid: number, offerid: number) {
    return this.apiService.post(httpMcaGenerateExhibitA(mcaid, offerid));
  }

  generateConsolidationGrid(mcaid: number, offerid: number) {
    return this.apiService.post(httpMcaGenerateConsolidationGrid(mcaid, offerid));
  }

  getOfferTemplates() {
    return this.apiService.get<OfferTemplate[]>(httpMcaOfferTemplate());
  }

  updateOfferTemplates(id: number, rec: any) {
    return this.apiService.put(httpMcaOfferTemplateId(id), rec);
  }

  createOfferTemplates(rec: any) {
    return this.apiService.post(httpMcaOfferTemplate(), rec);
  }

  deleteOfferTemplates(id: number) {
    return this.apiService.delete(httpMcaOfferTemplateId(id));
  }

  calcIncrementalTemplateFa(
    mcaId: number,
    data: {
      incr_schedule_pct: number[];
      max_exposure_pct: number;
      max_fa: number;
      rate: number;
      payment: number;
      contract_fee_pct: number;
      deposit_freq: number;
      payment_freq: number;
      days: number;
    },
  ) {
    return this.apiService.post<{
      exposure_pct: number;
      fa: number;
    }>(httpMcaOfferCalcIncrementalTemplateFa(mcaId), data);
  }

  geISOActiveOffers(status: number) {
    return this.apiService.get(httpISOActiveOffers(status));
  }

  verifyIsoOffer(mcaid: number, offer: McaOfferData) {
    return this.apiService.put<Partial<McaOfferData>>(httpIsoOfferVerify(mcaid, offer.id), offer);
  }

  createIsoOfferRequest(mcaid: number, offer: McaOfferData) {
    return this.apiService.put(httpIsoOfferRequest(mcaid, offer.id), offer);
  }
}
